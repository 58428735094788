import { ModalController, NavParams } from "@ionic/angular";
import { Component, OnInit } from '@angular/core';
import { UploadEvent, FileSystemFileEntry } from 'ngx-file-drop';
import { AlertService, ApiResponse } from 'ng-etg-base';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PropertyService } from '@services/property.service';

@Component({
  selector: 'eca-import-properties',
  templateUrl: './import-properties.component.html',
  styleUrls: ['./import-properties.component.scss'],
})
export class ImportPropertiesComponent implements OnInit {

  public allowedExtensions: string[] = [
    "csv"
  ];
  public dropOver: boolean = false;
  public selectedFile: File = undefined;
  public inRemNum: string = undefined;
  public current_auction_id: number = undefined;
  public processing: boolean = false;
  private importResponse: any = undefined;
  public importExecuted: boolean = false;
  public importAgain: boolean = false;

  constructor(
    private modalController: ModalController,
    private alertService: AlertService,
    private modalService: NgbModal,
    private propertyService: PropertyService,
    public params: NavParams
  ) {
    this.inRemNum = params.get('inRemNum') || "N/A";
    this.current_auction_id = params.get('current_auction_id');
   }

  ngOnInit() {}

  public onFileDropped(event: UploadEvent) {
    var droppedFile = event.files[0];

    if (droppedFile.fileEntry.isFile) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        var ext = file.name.substring(file.name.lastIndexOf(".") + 1, file.name.length);

        if (!this.allowedExtensions.some(e => e === ext)) {
          this.alertService.toastError("File type not allowed. Please only upload CSVs.");
          return;
        }

        this.selectedFile = file;
      });
    }
  }

  public importProperties() {
    var _this = this;
    var reader = new FileReader();
    if(this.selectedFile != undefined) {
      _this.processing = true;
      reader.readAsDataURL(this.selectedFile);
      reader.onload = function () {
        var base64Csv = reader.result;

        let encoded = base64Csv.toString().replace(/^data:(.*;base64,)?/, '');
        if ((encoded.length % 4) > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4));
        }

        _this.propertyService.importProperties(encoded, _this.current_auction_id).subscribe(
          (res: ApiResponse) => {
            if(res.success){
              _this.importResponse = res;
              _this.processing = false;
              
              if(_this.importResponse.errors != undefined && _this.importResponse.errors.length > 0){
                _this.alertService.toastWarning("Properties uploaded with <b>Errors</b>.<br/><br/><b><i>Please review the Errors underneath the Report.</i></b>", "Warning", 300000);
              }
              else{
                _this.alertService.toastSuccess("Properties uploaded successfully!");
              }
            }
            else{
              _this.importResponse = res;
              _this.processing = false;
              _this.alertService.toastError("There was a problem uploading the properties. Please review underneath the report.");
            }

            _this.importAgain = false;
            _this.importExecuted = true;
          },
          (err) => {
            console.log("Failure in Import Properties Component");
            console.log("propertyService.importProperties call failure: ", err);
            _this.processing = false;
            _this.alertService.toastError("There was a problem uploading the properties. Please try again.");
          }
        );
      };
      reader.onerror = function (error) {
        _this.processing = false;
        _this.alertService.toastError("There seems to be a problem with the CSV you've selected. Please try again or choose another file.");
      };
    }
    else {
      _this.processing = false;
      _this.alertService.toastError("No file selected for upload.");
    }

  }

  public cancel() {
    this.modalController.dismiss(this.importExecuted);
  }

  public onFileOver(event) {
    this.dropOver = true;
  }

  public onFileLeave(event) {
    this.dropOver = false;
  }

  public resetImport(){
    this.importAgain = true;
    this.selectedFile = undefined;
    this.importResponse = undefined;
  }

} // end class
