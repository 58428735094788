import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { Animation } from '@ionic/core';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FileDropModule } from 'ngx-file-drop';

import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ComponentsModule } from '@components/components.module';
import { AuthInterceptor } from 'ng-etg-base';

import { AppComponent } from './app.component';

// pages
import { PropertySearchPage } from '@pages/property-search/property-search.page';
import { BidderRegistrationPage } from '@pages/bidder-registration/bidder-registration.page';
import { PropertyDetailsPage } from '@pages/property-details/property-details.page';
import { AuctionReportsPage } from '@pages/auction-reports/auction-reports.page';
import { LiveAuctionPage } from '@pages/live-auction/live-auction.page';

// components
import { PropertiesGridComponent } from '@components/properties-grid/properties-grid.component';
import { ImportPropertiesComponent } from '@components/import-properties/import-properties.component';
import { AssignPurchaserComponent } from '@components/assign-purchaser/assign-purchaser.component';

import { ErrorInterceptor } from '@guards/error.interceptor';


@NgModule({
  declarations: [
    AppComponent,
    PropertySearchPage,
    PropertiesGridComponent,
    AssignPurchaserComponent,
    BidderRegistrationPage,
    PropertyDetailsPage,
    AuctionReportsPage,
    LiveAuctionPage,
    ImportPropertiesComponent,
  ],
  entryComponents: [
    AssignPurchaserComponent,
    ImportPropertiesComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule.forRoot({
      navAnimation: (AnimationC: Animation): Promise<Animation> => { return Promise.resolve(new AnimationC()); }
    }),
    AppRoutingModule,
    NgxDatatableModule,
    ScrollToModule,
    PdfViewerModule,
    FileDropModule,
    ComponentsModule,
    NgbModule,
    RouterModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
