import { AppSettingsService } from './../../_services/app-settings.service';
import { Location } from '@angular/common';
import { Component, SimpleChanges } from '@angular/core';
import { OverlayEventDetail } from '@ionic/core';
import { Events, ModalController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AlertService, ETGAuthService, AlertPosition} from 'ng-etg-base';

import { Property } from '@models/property.model';
import { PropertyAuction } from '@models/property-auction.model';
import { PropertyAuctionStatus } from '@models/property-auction-status.enum';
import { PropertyService } from '@services/property.service';
import { PropertyAuctionService } from '@services/property-auction.service';
import { BidService } from '@services/bid.service';
import { reject } from 'q';
import { RecordPaymentComponent } from '@components/record-payment/record-payment.component';
import { BidderService } from '@services/bidder.service';
import { AssignPurchaserComponent } from '@components/assign-purchaser/assign-purchaser.component';
import { saveAs } from 'file-saver';
import { FormValidationService } from '../../_services/helpers/form-validation.service';

@Component({
  selector: 'eca-property-details-page',
  templateUrl: 'property-details.page.html',
  styleUrls: ['property-details.page.scss'],
})
export class PropertyDetailsPage {
  public loading: boolean = true;
  public processing: boolean = false;
  public processingMessage: string = "Generating sales documents...";

  public appSettings: any[] = [];
  public property: Property;

  public auctionDetailsForm: FormGroup;
  public winBidForm: FormGroup;
  public paymentForm: FormGroup;
  public auctionDetailsSubmitted: boolean = false;
  get auctionDetailsFields() { return this.auctionDetailsForm.controls; }
  get winBidFields() { return this.winBidForm.controls; }
  get paymentFields() { return this.paymentForm.controls; }
  public bidderFirstName: string;
  public bidderLastName: string;
  public minDeposit: number;
  public totalDeposit: number;
  public validPayment: boolean = false;
  public hasPurchaser: boolean = false;
  public currentUser: string;
  public updatingBid: boolean = false;
  public recordingPayment: boolean = false;
  public updatingForeclosure: boolean = false;
  public updatingAuction: boolean = false;
  public currentAuctionId: number = -1;
  public downloadingDocs: boolean = false;
  public processingPurchaser: boolean = false;
  public winningBidNumError: boolean = false;
  public winningBidAmtError: boolean = false;
  private id: number = 0;
  private requiredBidAmount: number = 0;

  constructor(
    private events: Events,
    public ModalController: ModalController,
    private formBuilder: FormBuilder,
    private formValidationService: FormValidationService,
    private propertyService: PropertyService,
    private propertyAuctionService: PropertyAuctionService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private bidService: BidService,
    private appSettingsService: AppSettingsService,
    private bidderService: BidderService,
    private authService: ETGAuthService,
    private locationService: Location
  ) {
    this.auctionDetailsForm = this.formBuilder.group({
      openingBid: ['', [Validators.required, Validators.min(0), Validators.maxLength(100), Validators.pattern(/[0-9]+/)]],
      superBid: [false]
    });
    
    this.winBidForm = this.formBuilder.group({
      winBidNum: ['', [Validators.required, Validators.min(0), Validators.maxLength(100), Validators.pattern(/[0-9]+/)]],
      winBidAmt: ['', [Validators.required, Validators.min(0), Validators.maxLength(100), Validators.pattern(/[0-9]+/)]]
    });

    this.paymentForm = this.formBuilder.group({
      cashDeposit: ['', [Validators.min(0), Validators.maxLength(100), Validators.pattern(/[0-9]+/)]],
      checkDeposit: ['', [Validators.min(0), Validators.maxLength(100), Validators.pattern(/[0-9]+/)]]
    });

    this.paymentForm.valueChanges.subscribe(() => {
      
      if (this.calcDepositPayment() >= this.minDeposit) {
        this.validPayment = true;
      }
      else {
        this.validPayment = false;
      }
    });
  }

  ionViewWillEnter() {
    this.loading = true;

    this.authService.getCurrentUser().then((user) => {
      this.currentUser = user.authAttributes.find(a => a.Name == "sub").Value;
    })
    .catch((err) => {
      console.log("Failure in Property Details Page.");
      console.log("Failure to retrieve current user: ", err);
      this.alertService.toastError("Failure to get current user.");
    });

    this.id = Number(this.route.snapshot.paramMap.get('id'));
    this.appSettingsService.getAllAppSettings().subscribe((res) => {
      this.appSettings = res.records;
      this.currentAuctionId = this.appSettings.find(s => s.name == "auctionId").value;
      this.getProperty(this.id);
    },
    (err) => {
      console.log("Failure in Property Details Page.");
      console.log("Failed to get all app settings: ", err);
      this.alertService.toastError("Failed to retrieve app settings. Please try again.");
    });
  }

  ionViewWillLeave() {
    this.loading = true;
  }

  getProperty(id: number) {
    this.loading = true;
    this.propertyService.getPropertyById(id).subscribe((property: Property) => {
      this.property = property;

      if (this.isPropertyInAuction()) {
        this.requiredBidAmount = this.property.property_auction.opening_bid;
        this.auctionDetailsFields.openingBid.setValue(this.property.property_auction.opening_bid);
        this.auctionDetailsFields.superBid.setValue(this.property.property_auction.super_bid);

        this.paymentFields.cashDeposit.setValue(this.property.property_auction.cash_deposit);
        this.paymentFields.checkDeposit.setValue(this.property.property_auction.check_deposit);
        this.totalDeposit = this.calcTotalDeposit();
        
        if (this.property.property_auction.purchaser_id != null) {
          this.hasPurchaser = true;
        }
        
        if (this.hasWinningBid()) {
          this.determineMinDeposit();
          this.winBidFields.winBidNum.setValue(this.property.property_auction.winning_bid.bidder.bidder_number);
          this.winBidFields.winBidAmt.setValue(this.property.property_auction.winning_bid.amount);

          if (this.hasBidder()) {
            this.bidderFirstName = this.property.property_auction.winning_bid.bidder.first_name;
            this.bidderLastName = this.property.property_auction.winning_bid.bidder.last_name;
            this.loading = false;
          }
          else{
            this.loading = false;
          }
        }
        else{
          this.loading = false;
        }
      }
      else{
        this.loading = false;
      }

      this.loading = false;
    },
    (err) => {
      console.log("Failure in Property Details Page.");
      console.log("Failed to get property by it's ID: ", err);
      this.alertService.toastError("Could not retrieve property information. Please try again.");
    });
  }

  removeFromForeclosure() {
    var _this = this;
    this.processing = true;
    
    this.alertService.confirmError("Are you sure you'd like to remove this property from foreclosure?", function(confirmRes){
      if(confirmRes && confirmRes.value && confirmRes.value === true){
        // Verify that the property is in the auction
        if (_this.property.property_auction != null && _this.property.property_auction.active == true) {
          _this.updatingForeclosure = true;
          _this.property.property_auction.active = false;
          _this.property.property_auction.update_user = _this.currentUser;

          _this.propertyAuctionService.updatePropertyAuction(_this.property.property_auction).subscribe((res) => {
            _this.processing = false;
            _this.updatingForeclosure = false;
          },
          (err) => {
            console.log("Failure in Property Details Page.");
            console.log("Failed to remove from foreclosure: ", err);
            this.alertService.toastError("Failed to remove from foreclosure. Please try again.");
          });       
        }
      }
      else{
        _this.alertService.toastWarning("REMOVE FROM FORECLOSURE cancelled.");
        _this.processing = false;
        _this.updatingForeclosure = false;
      }
    }, 
    "Confirm Removal",
    "Yes", "No",
    null, 
    null, 
    AlertPosition.CENTER);   
  }

  addToForeclosure() {
    this.loading = true;
    this.processing = true;
    this.updatingForeclosure = true;
    // If the property has a PropertyAuction record that is inactive, activate it
    if (this.property.property_auction != null && this.property.property_auction.active == false) {
      this.property.property_auction.active = true;
      this.property.property_auction.update_user = this.currentUser;

      this.propertyAuctionService.updatePropertyAuction(this.property.property_auction).subscribe((res) => {
        this.getProperty(this.id);
        this.processing = false;
        this.updatingForeclosure = false;
      },
      (err) => {
        console.log("Failure in Property Details Page.");
        console.log("Failed to add to foreclosure: ", err);
        this.alertService.toastError("Failed to add from foreclosure. Please try again.");
      });
    }
  }

  isPropertyInAuction() {
    return this.property.property_auction != null && this.property.property_auction.active == true;
  }

  hasWinningBid() {
    return this.property.property_auction.winning_bid != null;
  }

  hasBidder() {
    return this.property.property_auction.winning_bid.bidder != null;
  }

  isPropertySold() {
    return this.isPropertyInAuction() && this.property.property_auction.property_auction_status_id == PropertyAuctionStatus.SOLD;
  }

  updateAuction() {
    this.auctionDetailsSubmitted = true;
    this.processing = true;
    this.updatingAuction = true;
    this.getFormValidationErrors();

    if (this.auctionDetailsForm.valid) {
      this.auctionDetailsSubmitted = true;
      this.updatingAuction = true;

      this.property.property_auction.opening_bid = this.auctionDetailsFields.openingBid.value;
      this.property.property_auction.super_bid = this.auctionDetailsFields.superBid.value;
      this.property.property_auction.update_user = this.currentUser;

      this.propertyAuctionService.updatePropertyAuction(this.property.property_auction).subscribe((res) => {
        this.alertService.toastSuccess("Auction details updated successfully");
        this.processing = false;
        this.updatingAuction = false;
      },
      (err) => {
        console.log("Failure in Property Details Page.");
        console.log("Failure to update auction details: ", err);
        this.alertService.toastError("Failed to update auction details. Please try again.");
        this.processing = false;
        this.updatingAuction = false;
      });
    }
    else{
      this.auctionDetailsSubmitted = false;
      this.updatingAuction = false;
      this.processing = false;
    }
  }

  // an easy way to get all errors in the control form without mucking up the html page with different edge cases
  getFormValidationErrors() {
    let _this = this;
    let errList = "";
    let validator = this.formValidationService.formValidation(this.auctionDetailsForm).then((err) => {
      if (err.length > 0) {
        err.forEach(error => {
          switch (error.errorKey) {
            case "required": {
              errList += `${error.controlName} is required.<br />`;
              break;
            }
            case "pattern": {
              errList += `${error.controlName}: Please match the pattern.<br />`;
              break;
            }
            case "maxlength": {
              errList += `${error.controlName}: The input is over the character limit for this field.<br />`;
              break;
            }
            default: {
              break;
            }
          }
        });
        _this.alertService.toastError(errList);
      }
    });
  }

  public updateBid() {
    var auctionId = this.appSettings.find(s => s.name == "auctionId").value
    var winningBidAmt = this.winBidForm.get('winBidAmt').value === null ? 0 : Number.parseFloat(this.winBidForm.get('winBidAmt').value);
    this.processing = true;
    this.updatingBid = true;

    if(this.requiredBidAmount > winningBidAmt){
      this.alertService.toastError('The Winning Bid Amount must be greater than or equal to the Opening Bid Amount of ' + this.requiredBidAmount.toString() + '. Please try again.');
      this.winningBidAmtError = true;
      this.processing = false;
      this.updatingBid = false;
    }
    else
    {
      this.winningBidAmtError = false;
      
      this.bidderService.getBidderByBidderNumber(this.winBidForm.get('winBidNum').value, auctionId).subscribe((bidderInfo) => {
        if(bidderInfo && bidderInfo.records && bidderInfo.records.length > 0){
          // Updating name to new bidder that won
          this.winningBidNumError = false;
          this.property.property_auction.winning_bid.bidder = bidderInfo.records[0];
          this.property.property_auction.winning_bid.amount = this.winBidForm.get('winBidAmt').value;
          this.property.property_auction.winning_bid.bidder_id = Number(bidderInfo.records[0].id);
          this.property.property_auction.winning_bid.update_user = this.currentUser;
  
          this.bidService.updateBid(this.property.property_auction.winning_bid).subscribe((data) => {
            this.determineMinDeposit();
  
            if (this.calcDepositPayment() >= this.minDeposit) {
              this.validPayment = true;
            }
            else {
              this.validPayment = false;
            }
  
            this.bidderFirstName = bidderInfo.records[0].first_name;
            this.bidderLastName = bidderInfo.records[0].last_name;
  
            this.alertService.toastSuccess("Bid Successfully Updated!");
            this.processing = false;
            this.updatingBid = false;
          },
          (err) => {
            console.log("Failure in Property Details Page.");
            console.log("Failure while attempting to update a bid: ", err);
            this.alertService.toastError("Failure while updating Bid. Please try again.");
            this.processing = false;
            this.updatingBid = false;
          });
        }
        else{
          this.alertService.toastError("The Winning Bidder # you entered does not match any Bidder in the current InRem. Please try again.");
          this.winningBidNumError = true;
          this.processing = false;
          this.updatingBid = false;
        }      
      },
      (err) => {
        console.log("Failure in Property Details Page.");
        console.log("Failure to retrieve bidder number: ", err);
        this.alertService.toastError("Failed to get bidder number for bid. Please try again.");
        this.processing = false;
        this.updatingBid = false;
      });
    }
  }

  public determineMinDeposit() {
    var minDep = this.appSettings.find(s => s.name == 'minimumDeposit').value;
    var percentBidAsDeposit = this.appSettings.find(s => s.name == 'percentOfBidAsDeposit').value;
    var percentBid = this.property.property_auction.winning_bid.amount * (percentBidAsDeposit / 100);

    (percentBid > minDep) ? (this.minDeposit = parseFloat(percentBid.toFixed(2))) : (this.minDeposit = minDep);
  }

  public calcDepositPayment(): Number {

    var cashDeposit = this.paymentForm.get('cashDeposit').value;
    var checkDeposit = this.paymentForm.get('checkDeposit').value;

    if(cashDeposit == null) {
      cashDeposit = 0;
    }
    if(checkDeposit == null) {
      checkDeposit = 0;
    }

    if(cashDeposit < 0 || checkDeposit < 0) {
      this.alertService.toastError("Deposit values cannot be negative");
      return null;
    }
    else {
      var floatCash = parseFloat(cashDeposit);
      var floatCheck = parseFloat(checkDeposit);
      var totalDeposit = (floatCash + floatCheck);
      return totalDeposit;
    }
  }

  public isPaymentRecorded() {
    return (this.property.property_auction.cash_deposit || this.property.property_auction.check_deposit);
  }

  public calcTotalDeposit(): number {
    var cashDeposit = this.paymentForm.get('cashDeposit').value;
    var checkDeposit = this.paymentForm.get('checkDeposit').value;

    if (cashDeposit == null) {
      cashDeposit = 0;
    }
    else {
      cashDeposit = parseFloat(cashDeposit);
    }

    if (checkDeposit == null) {
      checkDeposit = 0;
    }
    else {
      checkDeposit = parseFloat(checkDeposit);
    }

    var totalDeposit = cashDeposit + checkDeposit;
    return totalDeposit;
  }

  public async recordPayment() {

    var cashDeposit = this.paymentForm.get('cashDeposit').value;
    var checkDeposit = this.paymentForm.get('checkDeposit').value;

    if (cashDeposit == null) {
      cashDeposit = 0;
    }
    if (checkDeposit == null) {
      checkDeposit = 0;
    }

    var recordPaymentModal = await this.ModalController.create({
      component: RecordPaymentComponent,
      componentProps: {
        sbl: this.property.sbl,
        cash: cashDeposit,
        check: checkDeposit
      },
      animated: true,
      cssClass: 'eca-modal record-payment-modal'
    });

    recordPaymentModal.onDidDismiss().then((detail: OverlayEventDetail) => {
      if(detail.data == true) {
        this.processing = true;
        this.recordingPayment = true;
        this.property.property_auction.cash_deposit = this.paymentForm.get('cashDeposit').value;
        this.property.property_auction.check_deposit = this.paymentForm.get('checkDeposit').value;
        this.property.property_auction.update_user = this.currentUser;

        this.propertyAuctionService.updatePropertyAuction(this.property.property_auction).subscribe(() => {
          this.totalDeposit = this.calcTotalDeposit();
          this.alertService.toastSuccess("Payment Successfully Recorded!");
          this.processing = false;
          this.recordingPayment = false;
        },
        (err) => {
          console.log("Failure in Property Details Page.");
          console.log("Failed to update property auction with payment information: ", err);
          this.alertService.toastError("Failed to record payment. Please try again.");
          this.processing = false;
          this.recordingPayment = false;
        }
        );
      }
    });

    recordPaymentModal.present();
  }

  public clearPayment() {
    this.processing = true;
    this.property.property_auction.update_user = this.currentUser;
    var updatePA = new PropertyAuction(this.property.property_auction);
    updatePA.cash_deposit = null;
    updatePA.check_deposit = null;

    this.propertyAuctionService.updatePropertyAuction(updatePA).subscribe(() => {
      this.paymentForm.reset();
      this.property.property_auction.cash_deposit = null;
      this.property.property_auction.check_deposit = null;
      this.totalDeposit = this.calcTotalDeposit();
      this.alertService.toastSuccess("Payment Cleared!");
      this.processing = false;
    },
      (err) => {
        console.log("Failure in Property Details Page.");
        console.log("Failed to update property auction with payment information: ", err);
        this.alertService.toastError("Failed to clear payment. Please try again.");
        this.processing = false;
      }
    );
  }

  public async assignPurchaser() {
    var assignPurchaserModal = await this.ModalController.create({
      component: AssignPurchaserComponent,
      componentProps: {
        propertyId: this.property.property_auction.id
      },
      animated: true,
    });

    assignPurchaserModal.onDidDismiss().then((detail: OverlayEventDetail) => {
      if(detail.data == true) {
        this.hasPurchaser = true;
      }
    });

    assignPurchaserModal.present();
  }

  public removePurchaser() {
    var _this = this;
    this.processing = true;
    this.processingPurchaser = true;

    this.alertService.confirmError("Are you sure you want to remove the Purchaser from this foreclosure?", 
      function(confirmRes){
        if(confirmRes && confirmRes.value && confirmRes.value === true){
          _this.property.property_auction.purchaser_id = null;
          _this.propertyAuctionService.updatePropertyAuction(_this.property.property_auction).subscribe((data) => {
            _this.alertService.toastSuccess("Successfully removed purchaser from this property!");
            _this.hasPurchaser = false;
            _this.processingPurchaser = false;
            _this.processing = false;
          },
          (err) => {
            console.log("Failure in Property Details Page.");
            console.log("Failed to removed purchaser from property: ", err);
            _this.alertService.toastError("Failed to remove purchaser from this property. Please try again.");
            _this.processing = false;
            _this.processingPurchaser = false;
          });
        }
        else{
          _this.alertService.toastWarning("REMOVE PURCHASER cancelled.");
          _this.processing = false;
          _this.processingPurchaser = false;
        }
        
    }, "Confirm Purchaser Removal", "Yes", "No", null, null, AlertPosition.CENTER);
  }

  public downloadSaleDocuments() {
    this.processingMessage = "Generating sales documents...";
    this.processing = true;
    this.downloadingDocs = true;

    this.propertyAuctionService.generateSaleDocs(this.property.property_auction.id).subscribe((res) => {
      res.forEach(doc => {
        saveAs(b64toBlob(doc.base64), doc.filename);
      });
      
      this.processing = false;
      this.downloadingDocs = false;
    },
    (err) => {
      console.log("Failure in Property Details Page.");
      console.log("Failure while generating sales documents: ", err);
      this.alertService.toastError("Failed to download sales document. Please try again.");
      this.downloadingDocs = false;
      this.processing = false;
    });
  }

  navBack() {
    this.locationService.back();
  }
}

/*
  Solution obtained from StackOverflow for a more optimized way of converting from base64 string
  to a blob that can be downloaded.
  
  https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
*/
const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}