import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { IonicModule } from '@ionic/angular';

import { PageHeaderComponent } from '@components/page-header/page-header.component';
import { BackToTopComponent } from '@components/back-to-top/back-to-top.component';
import { RecordPaymentComponent } from '@components/record-payment/record-payment.component';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        RouterModule,
        NgbModule,
        ScrollToModule.forRoot()
    ],
    declarations: [
        BackToTopComponent,
        PageHeaderComponent,
        RecordPaymentComponent
    ],
    exports: [
        BackToTopComponent,
        PageHeaderComponent,
        RecordPaymentComponent
    ],
    entryComponents: [
        RecordPaymentComponent
    ]
})
export class ComponentsModule { }
