import { Component, OnInit, Input } from '@angular/core';
import { Property } from '@models/property.model';
import { Router } from "@angular/router";
import { Events } from '@ionic/angular';
import { PageRefService } from '@services/helpers/page-ref.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'eca-properties-grid',
  templateUrl: './properties-grid.component.html',
  styleUrls: ['./properties-grid.component.scss'],
})
export class PropertiesGridComponent implements OnInit {
  
  @Input() properties: Property[];
  @Input() totalItems: number;
  @Input() loading: boolean;
  @Input() parentEvents: Observable<void>;

  public shownProperties: Property[] = [];
  public property: Property;
  public currentPage = 1;
  public sortBy = "address";
  public ascending = true;
  private eventsSubscription: any;

  constructor(
    private router: Router,
    private pageRefService: PageRefService,
    private events: Events
    ) { }

  ngOnInit() {
    this.shownProperties = this.properties;
    this.eventsSubscription = this.parentEvents.subscribe(() => this.parentSearchResetRequest());
  }

  ngOnChanges(changes) {
    if(changes.properties == undefined) {
      return null;
    }
    else {
      this.shownProperties = changes.properties.currentValue;
    }
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }

  public sortProperties(sortBy: string, ascending) {
    this.sortBy = sortBy;
    this.ascending = ascending;
    this.events.publish("propertiesSortChange", {
      sortBy: sortBy,
      sortOrder: (ascending == true) ? "ascending" : "descending"
    });
  }

  public editProperty(property: Property) {
    this.router.navigate([`property-details/${property.id}`]);
  }

  public onPageChange(pageChange) {
    this.pageRefService.setPage(pageChange);
    this.events.publish("pageChange", pageChange);
  }

  private parentSearchResetRequest(){
    this.sortBy = "address";
    this.ascending = true;
  }

  public propertyPaymentRecorded(property) {
    return (property.cash_deposit || property.check_deposit);
  }
}