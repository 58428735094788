import { PropertyAuction } from '@models/property-auction.model';

export class Property {
    id: number;
    sbl: string;
    name: string;
    city: string;
    address: string;
    class: number;
    description: string;
    notes: string;
    active: boolean;
    date_created: string;
    create_user: string;
    date_updated: string;
    update_user: string;
    property_auction: PropertyAuction;

    constructor(obj?) {
        if (obj) {
            this.id = obj.id;
            this.sbl = obj.sbl;
            this.name = obj.name;
            this.city = obj.city;
            this.address = obj.address;
            this.class = obj.class;
            this.description = obj.description;
            this.notes = obj.notes;
            this.active = obj.active;
            this.date_created = obj.date_created;
            this.create_user = obj.create_user;
            this.date_updated = obj.date_updated;
            this.update_user = obj.update_user;
            if (obj.property_auction) {
                this.property_auction = new PropertyAuction(obj.property_auction);
            }
            else {
                this.property_auction = null;
            }
        }
    }
}