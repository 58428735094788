export class Auction {
    id: number;
    inrem: number;
    settlement_date: string;
    notice_of_pendency: string;
    date_of_auction: string;
    active: boolean;
    date_created: string;
    create_user: string;
    date_updated: string;
    update_user: string;

    constructor(obj?) {
        if (obj) {
            this.id = obj.id;
            this.inrem = obj.inrem;
            this.settlement_date = obj.settlement_date;
            this.notice_of_pendency = obj.notice_of_pendency;
            this.date_of_auction = obj.date_of_auction;
            this.active = obj.active;
            this.date_created = obj.date_created;
            this.create_user = obj.create_user;
            this.date_updated = obj.date_updated;
            this.update_user = obj.update_user;
        }
    }
}