import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {

  constructor(
    private httpClient: HttpClient
  ) { }

  /*
      Get the current inRem number.
      Method: GET
  */
  public getAppSetting(appSetting: string): Observable<any> {
    return this.httpClient
      .get(`${environment.apiUrl}/appsetting/${appSetting}`)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  /*
    Get the minimum deposit.
    Method: GET
  */
  public getAllAppSettings(): Observable<any> {
    return this.httpClient
      .get(`${environment.apiUrl}/appsetting`)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
}
