import { Component, OnInit } from '@angular/core';
import { AppSettingsService } from '@services/app-settings.service';
import { PropertyAuctionService } from '@services/property-auction.service';
import { PropertyAuctionSearch } from '../../_models/property-auction-search.model';
import { AlertService } from 'ng-etg-base';
import { FormBuilder, FormGroup, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { UserService } from 'ng-etg-base';
import { BidService } from '@services/bid.service';
import { BidderService } from '@services/bidder.service';
import { FormValidationService } from '@services/helpers/form-validation.service';
import { AuctionReportService } from '@services/auction-report.service';
import { DateService } from '@services/helpers/date.service';

@Component({
  selector: 'eca-live-auction',
  templateUrl: './live-auction.page.html',
  styleUrls: ['./live-auction.page.scss'],
})
export class LiveAuctionPage {

  public bidForm: FormGroup;
  public allowBidRecord: boolean = false;
  public currentInRem;
  public properties: PropertyAuctionSearch[] = [];
  public currentPage: number = 1;
  public loading: boolean = true;
  public loadingNextSet: boolean = false;
  public activeRecord = 0;
  get getBidFields() { return this.bidForm.controls; }
  public currentUser: string;
  public totalRecords: number;
  public pageSize: number = 5;
  public currentRecord: number = 0;
  public processing: boolean = false;
  public selling: boolean = false;
  public adjourning: boolean = false;
  public goBack: boolean = false;
  public onResultsPage: boolean = false;
  public onLastRecord = false;
  public winningBidAmtError: boolean = false;

  constructor(
    private appSettingsService: AppSettingsService,
    private propertyAuctionService: PropertyAuctionService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private bidService: BidService,
    private bidderService: BidderService,
    private formValidationService: FormValidationService,
    private reportService: AuctionReportService,
    private dateService: DateService
    ) { 

    this.bidForm = this.formBuilder.group({
      bidderNum: ['', [Validators.required, Validators.min(0), Validators.maxLength(100), Validators.pattern(/[0-9]+/)]],
      bidAmt: ['', [Validators.required, Validators.min(0), Validators.maxLength(100), Validators.pattern(/^\$?([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(.[0-9][0-9])?$/)]]
    });


    // checks to see if we are on the live auction results page
    if (window.location.href.indexOf("results") > -1) {
      this.allowBidRecord = true;
      this.onResultsPage = true;
    }
  }

  ionViewWillEnter() {
    this.loading = true;

    this.appSettingsService.getAppSetting('currentInRem').subscribe((res) => {
      this.currentInRem = res.records.find(s => s.name == "currentInRem").value;
      this.propertyAuctionService.searchPropertyAuction(this.currentRecord, this.currentInRem).subscribe((data) => {

        if(data.currentRecord == data.totalItems) {
          this.onLastRecord = true;
        }

        this.bidForm.reset();

        this.currentRecord = data.currentRecord;
        this.activeRecord = data.activeRecord - 1;
        this.currentPage = data.currentPage;
        this.properties = data.records;
        this.totalRecords = data.totalItems;
        this.canGoBack();
        this.loading = false;
      },
        (err) => {
          console.log("Failure in Live Auction Page");
          console.log("searchPropertyAuction call failed: ", err);
          this.alertService.toastError("Failed to load properties. Please try again.");
        });
    },
      (err) => {
        console.log("Failure in Live Auction Page");
        console.log("Failed to get current inrem: ", err);
        this.alertService.toastError("Failed to retrieve current inrem. Please try again.");
      });

    var getUser = this.userService.getCurrentUser();
    this.currentUser = getUser.authAttributes.find(a => a.Name == "sub").Value;
  }

  ionViewWillLeave() {
    this.loading = true;
  }

  public bidRecordingChange(event) {
    (event.target.checked) ? this.allowBidRecord = true : this.allowBidRecord = false;
  }

  public nextRecordSet() {

      // check to see if we are on the last record
      if(this.currentRecord == this.totalRecords) {
        this.alertService.toastWarning("End of Property List! Cannot proceed any further.");
        this.onLastRecord = true;
        return;
      }

      if (this.activeRecord != this.properties.length - 1) { // were not on the last record yet
        this.activeRecord += 1;
        this.currentRecord += 1;
        this.canGoBack();
      }
      else {
        this.currentRecord += 1;
        this.loadingNextSet = true;
        this.propertyAuctionService.searchPropertyAuction(this.currentRecord, this.currentInRem).subscribe((data) => {
          (!this.allowBidRecord) ? this.goBack = true : this.goBack = false;
          this.properties = data.records;
          this.currentRecord = data.currentRecord;
          this.activeRecord = data.activeRecord - 1;
          this.loadingNextSet = false;
        },
        (err) => {
          console.log("Failure in Live Auction Page");
          console.log("Failure in nextRecordSet function: ", err);
          this.alertService.toastError("Failed to retrieve next record set.");
        });
      }
  }

  public getPreviousRecordSet() {
      this.onLastRecord = false;

      if (this.activeRecord != 0) {
        this.activeRecord -= 1;
        this.currentRecord -= 1;
        this.canGoBack();
      }
      else {
        this.currentRecord -= 1;
        this.loadingNextSet = true;
        this.propertyAuctionService.searchPropertyAuction(this.currentRecord, this.currentInRem).subscribe((data) => {
          this.properties = data.records;
          this.activeRecord = this.properties.length - 1;
          this.currentRecord = data.currentRecord;
          this.canGoBack();
          this.loadingNextSet = false;
        },
        (err) => {
          console.log("Failure in Live Auction Page.");
          console.log("Failure in getPreviousRecordSet function: ", err);
          this.alertService.toastError("Failed to get previous record set.");
        });
      }
  }

  public adjournProperty() {
    this.processing = true;
    this.adjourning = true;
    this.properties[this.activeRecord].property_auction_status_id = 2;
    this.properties[this.activeRecord].update_user = this.currentUser;
    this.propertyAuctionService.updatePropertyAuction(this.properties[this.activeRecord]).subscribe((res) => {
      this.nextRecordSet();
      this.bidForm.reset();
      this.processing = false;
      this.adjourning = false;
    },
    (err) => {
      console.log("Failure in Live Auction Page");
      console.log("Failed to adjourn property: ", err);
      this.alertService.toastError("Could not adjourn property. Please try again.");
      this.processing = false;
      this.adjourning = false;
    })
  }

  public sellProperty() {

    this.selling = true;
    this.getFormValidationErrors();

    if(this.bidForm.valid) {
      var bidObj = {
        amount: this.bidForm.get('bidAmt').value,
        create_user: this.currentUser,
        property_auction_id: this.properties[this.activeRecord].id
      };

      if(this.properties[this.activeRecord].opening_bid > bidObj.amount){
        this.alertService.toastError('The Bid Amount must be greater than or equal to the Opening Bid Amount of ' + this.properties[this.activeRecord].opening_bid.toString() + '. Please try again.');
        this.selling = false;
      }
      else
      {
        this.properties[this.activeRecord].update_user = this.currentUser;

        // get bidder id by bidder num for creation of bid
        this.bidderService.getBidderByBidderNumber(this.bidForm.get('bidderNum').value, this.properties[this.activeRecord].auction_id).subscribe((res) => {
          if(res.records.length == 0) {
            this.alertService.toastError("The Winning Bidder # you entered does not match any Bidder in the current InRem. Please try again.");
            this.winningBidAmtError = true;
            this.selling = false;
          }
          else {
            this.winningBidAmtError = false;
            bidObj['bidder_id'] = res.records[0].id; // only one record show ever come back since bidder numbers should be unique in every auction

            // create the bid record first. Once that is successful, update the property auction record
            this.bidService.createBid(bidObj).subscribe((bidId) => {

              // update the property auction record to have a sold status
              this.properties[this.activeRecord].property_auction_status_id = 1;
              this.properties[this.activeRecord].winning_bid_id = bidId;
              this.propertyAuctionService.updatePropertyAuction(this.properties[this.activeRecord]).subscribe((res) => {
                this.alertService.toastSuccess("Sold property!");
                this.nextRecordSet();
                this.bidForm.reset();
                this.selling = false;
              },
              (err) => {
                console.log("Failure in Live Auction Page");
                console.log("Failed to update property auction: ", err);
                this.alertService.toastError("Could not sell property. Please try again.");
                this.selling = false;
              })
            },
            (err) => {
              console.log("Failure in Live Auction Page");
              console.log("Failed to create bid: ", err);
              this.alertService.toastError("Failed to create a bid for this property. Please try again.");
              this.selling = false;
            });
          }
        },
        (err) => {
          console.log("Failure in Live Auction Page");
          console.log("Failed to retrieve bidder number: ", err);
          this.alertService.toastError("Could not retrieve bidder number. Please try again.");
          this.selling = false;
        })
      }      
    }
    else{
      this.selling = false;
    }
  }

  // helpers
  getFormValidationErrors() {
    var errList = "";
    this.formValidationService.formValidation(this.bidForm).then((res) => {
      if(res.length > 0) {
        res.forEach(error => {
          switch (error.errorKey) {
            case "pattern":
              errList += `${error.controlName}: Please match the pattern.<br />`;
              break;
            case "required":
              errList += `${error.controlName} is required.<br />`;
              break;
            case "maxlength": {
              errList += `${error.controlName}: The input is over the character limit for this field.<br />`;
              break;
            }
            default:
              break;
          }
        });
        this.alertService.toastError(errList);
      }
    });
  }

  canGoBack() {
    // If on the first record of all records, do NOT allow going back
    if (this.currentRecord == 1) {
      this.goBack = false;
      return;
    }

    this.goBack = false;

    // If we do not allow bid recording and we are 
    if (this.allowBidRecord == false) {
      this.goBack = true;
    }
  }

  downloadAuctionReport() {
    this.processing = true;

    var reportParameters = {};
    reportParameters["data"] = { "inrem": parseInt(this.currentInRem) };
    reportParameters["type"] = "auction_overview_report";
    reportParameters["format"] = "csv";

    this.reportService.getReport(reportParameters).subscribe((report) => {
      saveAs(b64toBlob(report.report_data), "auction_overview_report-" + this.dateService.getTodaysDate() + ".csv");
      this.processing = false;
    },
      (err) => {
        console.log("Failure in Live Auction Page");
        console.log("downloadAuctionReport call failed while generating report: ", err);
        this.alertService.toastError("Failure to generate report. Please try again.");
        this.processing = false;
      });
  }
}

const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}
