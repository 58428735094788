import { PropertyAuctionStatus } from '@models/property-auction-status.enum';
import { Bid } from '@models/bid.model';
import { Auction } from '@models/auction.model';
import { Property } from '@models/property.model';

export class PropertyAuctionSearch {
    id: number;
    serial: number;
    opening_bid: number;
    super_bid: boolean;
    property_id: number;
    property_auction_status_id: PropertyAuctionStatus;
    winning_bid_id: number;
    purchaser_id: number;
    active: boolean;
    date_created: string;
    create_user: string;
    date_updated: string;
    update_user: string;
    cash_deposit: number;
    check_deposit: number;
    auction_id: number;
    original_auction_id: number;
    city: string;
    address: string;

    constructor(obj?) {
        if (obj) {
            this.id = obj.id;
            this.serial = obj.serial;
            this.opening_bid = obj.opening_bid;
            this.super_bid = obj.super_bid;
            this.property_id = obj.property_id;
            this.property_auction_status_id = obj.property_auction_status_id;
            this.winning_bid_id = obj.winning_bid_id;
            this.purchaser_id = obj.purchaser_id;
            this.active = obj.active;
            this.date_created = obj.date_created;
            this.create_user = obj.create_user;
            this.date_updated = obj.date_updated;
            this.update_user = obj.update_user;
            this.cash_deposit = obj.cash_deposit;
            this.check_deposit = obj.check_deposit;
            this.auction_id = obj.auction_id;
            this.original_auction_id = obj.original_auction_id;
            this.city = obj.city;
            this.address = obj.address;
        }
    }
}