import { Component, OnDestroy } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd } from '@angular/router';
import { Events, ModalController } from '@ionic/angular';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Property } from '@models/property.model';
import { PropertyService } from '@services/property.service';
import { OverlayEventDetail } from '@ionic/core';
import { ImportPropertiesComponent } from '@components/import-properties/import-properties.component';
import { AppSettingsService } from '@services/app-settings.service';
import { UserService, AlertService } from 'ng-etg-base';

@Component({
  selector: 'eca-property-search',
  templateUrl: './property-search.page.html',
  styleUrls: ['./property-search.page.scss'],
})
export class PropertySearchPage implements OnDestroy {

  public properties: Property[] = [];
  public propertySearchModel: any;
  public page = 1;
  public sortBy = "address";
  public sortOrder = "ascending";
  private propertySearchSubject: Subject<string> = new Subject<string>();
  public loading: boolean = false;
  public totalItems: number = 0;
  public gridReload: boolean = false;
  private inRemNum: string;
  private current_auction_id: number;
  public processingSearch: boolean = false;
  private locationSub: Subscription;
  private fromPopState: boolean = false;
  private searchResetRequestSubject: Subject<void> = new Subject<void>();

  constructor(
    private propertyService: PropertyService, 
    private events: Events, 
    public modalController: ModalController,
    private appSettingsService: AppSettingsService,
    private alertService: AlertService,
    private router: Router
  ) {
    // Latch onto router events to handle page state differences after link vaigation bs back button navigation
    this.locationSub = <Subscription>this.router.events.subscribe((event: Event) => {
      // At the start of navigation, check if the trigger was from a popstate (back button)
      if (event instanceof NavigationStart) {
        if (event.navigationTrigger == "popstate") {
          this.fromPopState = true;
        }
        else {
          this.fromPopState = false;
        }
      }

      // At the end of navigation, if the routing ends up on the properties page and popstate is false, we can reset the page state.
      // Otherwise retain the previous page state because we came from the back button.
      if (event instanceof NavigationEnd) {
        if ((event.url == "/properties" || event.url == "/") && this.fromPopState == false) {
          this.propertySearchModel = "";
          this.page = 1;
          this.sortBy = "address";
          this.sortOrder = "ascending";
          this.searchResetRequestSubject.next();
        }
      }
    });

    // Properties grid pagination change
    this.events.subscribe("pageChange", (pageNum) => {
      this.page = pageNum;
      this.pageChange();
    });

    // Properties grid sort change
    this.events.subscribe("propertiesSortChange", (data) => {
      this.sortBy = data.sortBy;
      this.sortOrder = data.sortOrder;
      this.propertiesSortChange();
    });
    
    // We are using the Subject Object in order to utilize the debounce functionality
    // because it will not work as excepted with just a string as the value
    this.propertySearchSubject
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe(model => {
        this.propertySearchModel = model;
        this.gridReload = true;
        this.getProperties();
      }); 
  }

  ionViewWillEnter() {
    this.loading = true;
    
    this.appSettingsService.getAppSetting('currentInRem').subscribe((data) => {
      this.inRemNum = data.records[0].value;
    },
    (err) => {
      console.log("Failure in Property Search Page.");
      console.log("Failed to get the current inrem: ", err);
      this.alertService.toastError("Failed to retrieve current inrem.");
    });

    this.appSettingsService.getAppSetting('auctionId').subscribe((data) => {
      this.current_auction_id = data.records[0].value;
    },
    (err) => {
      console.log("Failure in Property Search Page.");
      console.log("Failed to get current auction id: ", err);
      this.alertService.toastError("Failed to retrieve current auction id.");
    });
    this.getProperties();    
  }

  ionViewWillLeave() {
    this.loading = false;
  }

  ngOnDestroy() {
    this.events.unsubscribe("pageChange");
    this.events.unsubscribe("propertiesSortChange");
    this.locationSub.unsubscribe();
  }

  public propertySearch(text: string) {
    this.propertySearchSubject.next(text);
  }

  public pageChange() {
    this.gridReload = true;
    this.getProperties();
  }

  public propertiesSortChange() {
    this.gridReload = true;
    this.getProperties();
  }

  private getProperties(page?, searchTerm?, sortBy?, sortOrder?) {
    if (!page) page = this.page;
    if (!searchTerm) searchTerm = this.propertySearchModel || '';
    if (!sortBy) sortBy = this.sortBy;
    if (!sortOrder) sortOrder = this.sortOrder;

    this.propertyService.searchProperties(searchTerm.trim(), page, sortBy, sortOrder).subscribe((data) => {
      this.totalItems = data.totalItems;
      this.properties = data.records;
      this.gridReload = false;
      this.loading = false;
      this.processingSearch = false;
      this.events.publish("loading", false);
    },
    (err) => {
      console.log("Failure in Property Search Page.");
      console.log("Failed to populate properties from search: ", err);
      this.alertService.toastError("Failed to load properties. Please try again.");
      this.loading = false;
      this.events.publish("loading", false);
    });
  }

  public async importProperties() {
    var importPropertiesModal = await this.modalController.create({
      component: ImportPropertiesComponent,
      cssClass: 'eca-modal',
      componentProps: {
        inRemNum: this.inRemNum,
        current_auction_id: this.current_auction_id
      },
      animated: true,
      backdropDismiss: false
    });

    importPropertiesModal.onDidDismiss().then((detail: OverlayEventDetail) => {
      // Refresh the grid to see updated values
      this.page = 1;

      if(detail.data){
        this.loading = true;
        this.processingSearch = true;
        this.getProperties();
      }      
    });

    importPropertiesModal.present();
  }

  public clearSearchTerm(){
    this.propertySearchSubject.next("");
  }
} // end class
