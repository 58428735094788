import { Component, OnInit } from '@angular/core';
import { StateProvince } from '@models/states.model';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { FormValidationService } from "@services/helpers/form-validation.service";
import { AlertService, ETGAuthService } from "ng-etg-base";
import { Bidder } from "@models/bidder.model";
import { BidderService } from "@services/bidder.service";
import { AppSettingsService } from "@services/app-settings.service";


@Component({
  selector: 'eca-bidder-registration',
  templateUrl: './bidder-registration.page.html',
  styleUrls: ['./bidder-registration.page.scss'],
})
export class BidderRegistrationPage {

  public loading: boolean = false;
  public registering: boolean = false;
  public registerBidderForm: FormGroup;
  public stateProvince: StateProvince = new StateProvince();
  public states: any;
  public provinces: any;
  public errors = [];
  public bidderNumber: string;
  public inremNum: string;
  public auctionId;
  public currentUser: string;
  get bidderDetailsFields() { return this.registerBidderForm.controls; }

  constructor(
    private formBuilder: FormBuilder,
    private formValidationService: FormValidationService,
    private alertService: AlertService,
    private bidderService: BidderService,
    private appSettingsService: AppSettingsService,
    private authService: ETGAuthService
  ) {
    this.registerBidderForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.min(0), Validators.maxLength(100), Validators.pattern(/^[a-zA-Z0-9 \-,.']*$/)]],
      lastName: ['', [Validators.required, Validators.min(0), Validators.maxLength(100), Validators.pattern(/^[a-zA-Z0-9 \-,.']*$/)]],
      deedName: ['', [Validators.min(0), Validators.maxLength(100), Validators.pattern(/^[a-zA-Z0-9 \-,.']*$/)]],
      address1: ['', [Validators.required, Validators.min(0), Validators.maxLength(100), Validators.pattern(/^[a-zA-Z0-9 \-,.']*$/)]],
      address2: ['', [Validators.min(0), Validators.maxLength(100), Validators.pattern(/^[a-zA-Z0-9 \-,.']*$/)]],
      city: ['', [Validators.required, Validators.min(0), Validators.maxLength(100), Validators.pattern(/^[a-zA-Z0-9 \-,.']*$/)]],
      stateProvince: ['New York', [Validators.required, Validators.min(0), Validators.maxLength(100)]],
      zipcode: ['', [Validators.required, Validators.min(0), Validators.maxLength(100), Validators.pattern(/^[a-zA-Z0-9 -]*$/)]],
      phone: ['', [Validators.required, Validators.min(0), Validators.pattern(/^\(?([0-9]{3})\)?[-.●\s]?([0-9]{3})[-.●]?([0-9]{4})$/)]],
      email: ['', [Validators.min(0), Validators.maxLength(100), Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]],
      notes: ['', [Validators.min(0), Validators.maxLength(240), Validators.pattern(/^[a-zA-Z0-9 \-,.'!@#&*_=+^:;%$|?()]*$/)]]
    });

    this.states = this.stateProvince.states;
    this.provinces = this.stateProvince.provinces;
  }

  ionViewWillEnter() {
    this.loading = true;

    this.appSettingsService.getAppSetting("currentInRem").subscribe((data) => {
      this.inremNum = data.records[0].value;
      this.loading = false;
    },
    (err) => {
      console.log("Failure in Bidder Registration page.");
      console.log("Failed to get currentInRem from app settings: ", err);
      this.alertService.toastError("Failed to retrieve current inrem. Please try again.");
    });

    this.appSettingsService.getAppSetting("auctionId").subscribe((data) => {
      this.auctionId = data.records[0].value;
    },
    (err) => {
      console.log("Failure in Bidder Registration page.");
      console.log("Failed to get auctionId from app settings: ", err);
      this.alertService.toastError("Failed to retrieve auction Id. Please try again.");
    });

    this.authService.getCurrentUser().then((user) => {
      this.currentUser = user.authAttributes.find(a => a.Name == "sub").Value;
    })
    .catch((err) => {
      console.log("Failure in Bidder Registration page.");
      console.log("Failed to get current user: ", err);
      this.alertService.toastError("Failed to retrieve current user. Please try again.");
    });
  }

  ionViewWillLeave() {
    this.loading = true;
  }

  public registerBidder() {
    this.registering = true;
    this.getFormValidationErrors();

    if (this.registerBidderForm.valid) {
      let bidder = new Bidder();
      
      
      bidder.auction_id = this.auctionId;
      bidder.first_name = this.registerBidderForm.get('firstName').value;
      bidder.last_name = this.registerBidderForm.get('lastName').value;
      bidder.name_on_deed = this.registerBidderForm.get('deedName').value;
      bidder.address_1 = this.registerBidderForm.get('address1').value;
      bidder.address_2 = this.registerBidderForm.get('address2').value;
      bidder.city = this.registerBidderForm.get('city').value;
      bidder.state = this.registerBidderForm.get('stateProvince').value;
      bidder.zipcode = this.registerBidderForm.get('zipcode').value;
      bidder.notes = this.registerBidderForm.get('notes').value;
      bidder.phone = this.registerBidderForm.get('phone').value;
      bidder.email = this.registerBidderForm.get('email').value;

      var bidPhone = bidder.phone.replace(/\D/g, '');
      bidder.phone = bidPhone;

      bidder.create_user = this.currentUser;

      this.bidderService.registerBidder(bidder).subscribe((bidNum) => {
        this.bidderNumber = bidNum.bidNum;
        this.alertService.toastSuccess("Bidder successfully registered. Provide the generated bidder number and reset the form to register another.");
        this.registering = false;
      },
      (err) => {
        console.log("Failure in Bidder Registration Page.");
        console.log("Failed to register bidder: ", err);
        this.alertService.toastError("Could not register bidder. Please try again.");
        this.registering = false;
      });
    }
    else{
      this.registering = false;
    }
  }

  // an easy way to get all errors in the control form without mucking up the html page with different edge cases
  getFormValidationErrors() {
    let _this = this;
    let errList = "";
    let validator = this.formValidationService.formValidation(this.registerBidderForm).then((err) => {
      if (err.length > 0) {
        err.forEach(error => {
          switch (error.errorKey) {
            case "required": {
              errList += `${error.controlName} is required.<br />`;
              break;
            }
            case "pattern": {
              errList += `${error.controlName}: Please match the pattern.<br />`;
              break;
            }
            case "maxlength": {
              errList += `${error.controlName}: The input is over the character limit for this field.<br />`;
              break;
            }
            default: {
              break;
            }
          }
        });
        _this.alertService.toastError(errList);
      }
    });
  }

  public resetForm() {
    this.bidderNumber = "";
    this.registerBidderForm.reset();
  }
}
