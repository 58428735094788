import { Bidder } from '@models/bidder.model';

export class Bid {
    id: number;
    bidder_id: number;
    amount: number;
    property_auction_id: number;
    active: number;
    date_created: string;
    create_user: string;
    date_updated: string;
    update_user: string;
    bidder: Bidder;

    constructor(obj?) {
        if (obj) {
            this.id = obj.id;
            this.bidder_id = obj.bidder_id;
            this.amount = obj.amount;
            this.property_auction_id = obj.property_auction_id;
            this.active = obj.active;
            this.date_created = obj.date_created;
            this.create_user = obj.create_user;
            this.date_updated = obj.date_updated;
            this.update_user = obj.upate_user;

            if (obj.bidder) {
                this.bidder = new Bidder(obj.bidder);
            }
            else {
                this.bidder = null;
            }
        }
    }
}