import { Injectable } from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormValidationService {

  constructor() { }

  public formValidation(formGroup: FormGroup): Promise<any> {
    return new Promise((resolve, reject) => {
      let errors = [];

      Object.keys(formGroup.controls).forEach(key => {

        const controlErrors: ValidationErrors = formGroup.get(key).errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach(keyError => {
            let errSub = key.replace(/([A-Z])/g, " $1");
            let errName = errSub.charAt(0).toUpperCase() + errSub.slice(1);
            errors.push({
              controlName: errName,
              errorKey: keyError
            });
          });

          // Mark the control as dirty, which will force the error style around the control.
          formGroup.get(key).markAsDirty();

          resolve(errors);
        }
      });
    });
  }
}
