import { Component, Input, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { Location } from '@angular/common';
import { Events } from '@ionic/angular';

import { DateService } from '@services/helpers/date.service';
import { AuthService } from '@services/auth.service';
import { UserService, BaseUser, AlertService, CognitoService } from 'ng-etg-base';

@Component({
  selector: 'page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {

  @Input() title: string = "";

  public currentUser: BaseUser;
  public userEmail: string = "Error loading user email";
  private intervalId: any;

  constructor(
    private router: Router,
    public dateService: DateService,
    private locationService: Location,
    private authService: AuthService,
    private userService: UserService,
    private alertService: AlertService,
    private cognitoService: CognitoService,
    private events: Events
  ) { }

  ngOnInit() {
    this.currentUser = this.userService.getCurrentUser();

    if (Object.entries(this.currentUser.authAttributes).length != 0 && this.currentUser.authAttributes.constructor != Object) {
      this.userEmail = this.currentUser.authAttributes.find(a => a.Name == "email").Value;
    }
  }

  logout() {
    this.authService.logout().then((res) => {
      this.events.publish("loading", true);
      this.router.navigate(['/login']);
    })
    .catch((err) => {
      console.log("Page Header Failure");
      console.log("Logout failure: ", err);
      this.alertService.toastError("Failed to log out. Please try again.");
    });
  }
}
