import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '@services/auth.service';
import { AlertService, UserService } from 'ng-etg-base';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService,
        private alertService: AlertService,
        private userService: UserService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return new Promise((resolve, reject) => {
            var isAuth = this.authService.isAuthenticated();

            if (isAuth == undefined) {
                this.router.navigate(['/login']);
                resolve(false);
                return;
            }

            this.authService.isAuthenticated().then((isAuth) => {
                if (!isAuth) {
                    this.router.navigate(['/login']);
                    resolve(isAuth);
                }
                else {
                    this.authService.getCurrentUser()
                        .then((user) => {
                            resolve(isAuth);
                        });
                }
            });
        });
    }
}