import { Component } from '@angular/core';

import { Platform, Events } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router, ResolveEnd } from '@angular/router';
import { environment } from '@environments/environment';
import { ETGAuthService, AlertService, AlertPosition } from 'ng-etg-base';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  public showSidebar: boolean;
  public loading: boolean;
  public currentRoute: string;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    public events: Events,
    private etgAuth: ETGAuthService,
    private alertService: AlertService
  ) {
    this.initializeApp();

    this.events.subscribe("loading", (isLoading) => {
      this.loading = isLoading;
    });

    this.etgAuth.setAuthConfig({
      user_pool_id: environment.userPoolId,
      app_client_id: environment.appClientId,
      region: environment.region,
      access_key_id: environment.accessKeyId,
      secret_access_key: environment.secretAccessKey
    });

    this.alertService.setGlobalPosition(AlertPosition.BOTTOM_RIGHT);
    this.alertService.setGlobalTimeout(8000);
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  ionViewWillUnload() {
    this.events.unsubscribe("loading");
  }
}
