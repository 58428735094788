import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BidService {

  constructor(private httpClient: HttpClient) { }

  /*
    Update bid.
    Method: PUT

    @param item: the Bid record with the updated values.
    @returns: string
*/
  public updateBid(item): Observable<any> {
    return this.httpClient
      .put(`${environment.apiUrl}/bid/${item.id}`, item)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  /*
  Create bid.
  Method: POST

  @param item: the Bid record with the updated values.
  @returns: string
*/
  public createBid(item): Observable<any> {
    return this.httpClient
      .post(`${environment.apiUrl}/bid`, item)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
}
