export const environment = {
	production: true,

	apiUrl: "https://ofb1zhljbd.execute-api.us-east-1.amazonaws.com/Prod",

	userPoolId: "us-east-1_cf35fdhqf",
	appClientId: "2jk5s5v92r32dbrq4qgq2h3k00",
	region: "us-east-1",
	accessKeyId: "AKIAT7XDBCZYO3QQWMAF",
	secretAccessKey: "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"
};
