import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiResponse } from '@models/api-response.model';
import { Property } from '@models/property.model';
import { PropertyAuction } from '@models/property-auction.model';

@Injectable({
    providedIn: 'root',
})
export class PropertyService {

    constructor(
        private httpClient: HttpClient
    ) { }

    /*
        Get all properties.
        Method: GET

        @param page: the page number of records to retrieve.
        @param sort: the column to sort by.
        @param order: the order to sort by (ascending or descending).
        @returns: Property[]
    */
    public getAllProperties(page: number = 1, sort: string = "id", order: string = "ascending"): Observable<ApiResponse> {
        return this.httpClient
            .get(`${environment.apiUrl}/property?page=${page}&sort=${sort}&order=${order}`)
            .pipe(
                map((res: ApiResponse) => {
                    res.records.map((p) => { return new Property(p); });
                    return res;
                })
            );
    }

    /*
        Get property by id.
        Method: GET

        @param id: the id of the record to retrieve.
        @returns: Property
    */
    public getPropertyById(id: any): Observable<Property> {
        return this.httpClient
            .get(`${environment.apiUrl}/property/${id}`)
            .pipe(
                map((res: ApiResponse) => {
                    return new Property(res.records[0]);
                })
            );
    }

    /*
        Search properties.
        Method: POST

        @param query: the query string to search by.
        @param page: the page number of records to retrieve.
        @param sort: the column to sort by.
        @param order: the order to sort by (ascending or descending).
        @returns: Property[]
    */
    public searchProperties(query: string, page: number = 1, sort: string = "id", order: string = "ascending"): Observable<ApiResponse> {
        return this.httpClient
            .post(`${environment.apiUrl}/property/search?page=${page}&sort=${sort}&order=${order}`, { query: query })
            .pipe(
                map((res: ApiResponse) => {
                    res.records.map((p) => { return new Property(p); });
                    return res;
                })
            );
    }

    /*
        Upload CSV file.
        Method: POST

        @param file: the file to be uploaded.
    */
    public importProperties(file: any, currentAuctionId: number): any /*Observable<any>*/ {
      return this.httpClient
        .post(`${environment.apiUrl}/property/import`, { fileData: file, current_auction_id: currentAuctionId })
        .pipe(
          map((res) => {
            return res;
          })
        );
    }
}