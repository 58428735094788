import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiResponse } from '@models/api-response.model';
import { Property } from '@models/property.model';
import { PropertyAuction } from '@models/property-auction.model';
import { PropertyAuctionSearch } from '@models/property-auction-search.model';

@Injectable({
    providedIn: 'root',
})
export class PropertyAuctionService {

    constructor(
        private httpClient: HttpClient
    ) { }

    /*
        Update property auction.
        Method: PUT

        @param item: the PropertyAuction record with the updated values.
        @returns: string
    */
    public updatePropertyAuction(item): Observable<any> {
      return this.httpClient
          .put(`${environment.apiUrl}/property/auction/${item.id}`, item)
          .pipe(
              map((res) => {
                  return res;
              })
          );
    }

    /*
        Create property auction.
        Method: POST

        @param item: the PropertyAuction record to create.
        @returns: string
    */
    public createPropertyAuction(item): Observable<any> {
        return this.httpClient
            .post(`${environment.apiUrl}/property/auction`, item)
            .pipe(
                map((res) => {
                    return res;
                })
            );
    }

    /*
        Search for a property auction.
        Method: POST

        @param pageNum: the current page number that determines what is returned from query.
        @param query: the query of what to search by.
        @returns: string
    */
    public searchPropertyAuction(currentRecord, inRem): Observable<any> {
      var query = {
        query: inRem
      }
      return this.httpClient
        .post(`${environment.apiUrl}/property/auction/search?record=${currentRecord}`, query)
        .pipe(
          map((res: ApiResponse) => {
            res.records.map((pas) => { return new PropertyAuctionSearch(pas); });
            return res;
          })
        );
    }

    /*
        Generate sale documents.
        Method: POST

        @param propertyAuctionId: the ID of the PropertyAuction record to generate sale documents for.
        @returns: a payload containing various base64 encoded documents and their filenames.
    */
    public generateSaleDocs(propertyAuctionId): Observable<any> {
        return this.httpClient
            .post(`${environment.apiUrl}/saledocuments`, { type: "sale_documents", data: { property_auction_id: propertyAuctionId } })
            .pipe(
                map((res) => {
                    return res;
                })
            );
    }
}