import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse } from '@models/api-response.model';

@Injectable({
  providedIn: 'root'
})
export class AuctionReportService {

  constructor(private httpClient: HttpClient) { }

  /*
  Create a report.
  Method: POST

  @param item: the parameters that determine how the report will get returned
  @returns: object
*/
  public getReport(item): Observable<any> {
    return this.httpClient
      .post(`${environment.apiUrl}/report`, item)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

/*
  Get all auctions so we can retrieve all inrem numbers.
  Method: Get

  @returns: object
*/
  public getAllInRems(): Observable<any> {
    return this.httpClient
      .get(`${environment.apiUrl}/auction`)
      .pipe(
        map((res: ApiResponse) => {
          let inRemList = [];
          res.records.forEach(element => {
            inRemList.push(element.inrem);
          });
          return res;
        })
      );
  }
}

