import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Purchaser } from '@models/purchaser.model';

@Injectable({
  providedIn: 'root'
})
export class PurchaserService {

  constructor(private httpClient: HttpClient) { }

  /*
    Creates a purchaser
    Method: POST

    @param purchaser: object of all form values entered to create a purchaser
*/
  public createPurchaser(purchaser: Purchaser): Observable<any> {
    return this.httpClient
      .post(`${environment.apiUrl}/purchaser`, purchaser)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  /*
    Removes a Purchaser from a PropertyAuction record
    Method: PUT
  */
  // public removePurchaser(): Observable<any> {
  //   return this.httpClient
  //     .put(`${environment.apiUrl}/purchaser`, purchaser)
  //     .pipe(
  //       map((res) => {
  //         return res;
  //       })
  //     );
  // }

  /*
    Removes a Purchaser from a PropertyAuction
    Method: PUT

    @param propertyAuctionId: id of the PropertyAuction record we want to update
    @param purchaserId: id of the purchaser to link to the PropertyAuction record
  */
  public assignPurchaser(propertyAuctionId, purchaserId): Observable<any> {
    return this.httpClient
      .put(`${environment.apiUrl}/property/auction`, propertyAuctionId, purchaserId)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
}
