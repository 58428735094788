import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, ValidationErrors } from '@angular/forms';
import  { ModalController } from '@ionic/angular';
import { StateProvince } from '@models/states.model';
import { FormValidationService } from '@services/helpers/form-validation.service';
import { AlertService, ETGAuthService } from 'ng-etg-base';
import { PurchaserService } from '@services/purchaser.service';
import { Purchaser } from '@models/purchaser.model';

@Component({
  selector: 'eca-assign-purchaser',
  templateUrl: './assign-purchaser.component.html',
  styleUrls: ['./assign-purchaser.component.scss'],
})
export class AssignPurchaserComponent implements OnInit {

  public assignPurchaserForm: FormGroup;
  public stateProvince: StateProvince = new StateProvince();
  public states: any;
  public provinces: any;
  get purchaserDetailsFields() { return this.assignPurchaserForm.controls; }
  public errors = [];
  private propertyId: number;
  public processing: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private modalController: ModalController,
    private formValidationService: FormValidationService,
    private alertService: AlertService,
    private purchaserService: PurchaserService,
    private authService: ETGAuthService
    ) {

      this.assignPurchaserForm = this.formBuilder.group({
        firstName: ['', [Validators.required, Validators.min(0), Validators.maxLength(100), Validators.pattern(/^[a-zA-Z0-9 \-,.']*$/)]],
        lastName: ['', [Validators.required, Validators.min(0), Validators.maxLength(100), Validators.pattern(/^[a-zA-Z0-9 \-,.']*$/)]],
        deedName: ['', [Validators.min(0), Validators.maxLength(100), Validators.pattern(/^[a-zA-Z0-9 \-,.']*$/)]],
        address1: ['', [Validators.required, Validators.maxLength(100), Validators.min(0), Validators.pattern(/^[a-zA-Z0-9 \-,.']*$/)]],
        address2: ['', [Validators.min(0), Validators.maxLength(100), Validators.pattern(/^[a-zA-Z0-9 \-,.']*$/)]],
        city: ['', [Validators.required, Validators.min(0), Validators.maxLength(100), Validators.pattern(/^[a-zA-Z0-9 \-,.']*$/)]],
        stateProvince: ['New York', [Validators.required, Validators.min(0)]],
        zipcode: ['', [Validators.required, Validators.min(0), Validators.maxLength(100), Validators.pattern(/^[a-zA-Z0-9-]*$/)]],
        phone: ['', [Validators.required, Validators.min(0), Validators.pattern(/^\(?([0-9]{3})\)?[-.●\s]?([0-9]{3})[-.●]?([0-9]{4})$/)]],
        email: ['', [Validators.min(0), Validators.maxLength(100), Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)]],
        notes: ['', [Validators.min(0), Validators.maxLength(240), Validators.pattern(/^[a-zA-Z0-9 \-,.'!@#&*_=+^:;%$|?()]*$/)]]
      });
      
      this.states = this.stateProvince.states;
      this.provinces = this.stateProvince.provinces;
    }

  ngOnInit() {}

  public cancel() {
    this.modalController.dismiss();
  }

  assignPurchaser() {
    this.getFormValidationErrors();

    if (this.assignPurchaserForm.valid) {
      this.processing = true;
      let purchaser = new Purchaser();
      purchaser.first_name = this.assignPurchaserForm.get("firstName").value;
      purchaser.last_name = this.assignPurchaserForm.get("lastName").value;
      purchaser.name_on_deed = this.assignPurchaserForm.get("deedName").value;
      purchaser.address_1 = this.assignPurchaserForm.get("address1").value;
      purchaser.address_2 = this.assignPurchaserForm.get("address2").value;
      purchaser.city = this.assignPurchaserForm.get("city").value;
      purchaser.state = this.assignPurchaserForm.get("stateProvince").value;
      purchaser.zipcode = this.assignPurchaserForm.get("zipcode").value;
      purchaser.phone = this.assignPurchaserForm.get("phone").value;
      purchaser.email = this.assignPurchaserForm.get("email").value;
      purchaser.property_auction_id = this.propertyId;
      
      this.authService.getCurrentUser().then((user) => {
        purchaser.create_user = user.authAttributes.find(a => a.Name == "sub").Value;

        this.purchaserService.createPurchaser(purchaser).subscribe((data) => {
          this.alertService.toastSuccess("Successfully created purchaser for this property!");
          this.modalController.dismiss(true);
          this.processing = false;
        },
        (err) => {
          console.log("Failure in Assign Purchaser Modal");
          console.log("createPurchaser call failure: ", err);
          this.alertService.toastError("Could not create Purchaser. Please try again.");
          this.processing = false;
        });
      })
      .catch((err) => {
        console.log("Failure in Assign Purchaser Modal");
        console.log("getCurrentUser call failure: ", err);
        this.alertService.toastError("Could not recieve the current user. Please try again.");
        this.processing = false;
      });
    }
  }

  // an easy way to get all errors in the control form without mucking up the html page with different edge cases
  getFormValidationErrors() {
    let _this = this;
    let errList = "";
    let validator = this.formValidationService.formValidation(this.assignPurchaserForm).then((err) => {
      if(err.length > 0) {
        err.forEach(error => {
          switch (error.errorKey) {
            case "required": {
              errList += `${error.controlName} is required.<br />`;
              break;
            }
            case "pattern": {
              errList += `${error.controlName}: Please match the pattern.<br />`;
              break;
            }
            case "maxlength": {
              errList += `${error.controlName}: The input is over the character limit for this field.<br />`;
              break;
            }
            default: {
              break;
            }
          } 
        });
        _this.alertService.toastError(errList);
      }
    });
  }

} // end class
