import { Component, OnInit } from '@angular/core';
import { AuctionReportService } from '@services/auction-report.service';
import { saveAs } from 'file-saver';
import { DateService } from '@services/helpers/date.service';
import { Observable, Subscription, Subject } from 'rxjs';
import { AlertService } from 'ng-etg-base';
import { AppSettingsService } from '@services/app-settings.service';

@Component({
  selector: 'eca-auction-reports',
  templateUrl: './auction-reports.page.html',
  styleUrls: ['./auction-reports.page.scss'],
})
export class AuctionReportsPage {

  public loading: boolean = false;
  public inRemList: any;
  private reportParameters = {};
  public currentInRem;
  public selectedInrem;
  public currentInRemDisplay;
  public reportGenerated = false;
  public totalPropSold;
  public totalPropAdjourned;
  public totalCashDeposits;
  public totalCheckDeposits;
  public totalDeposits;
  public generatingReport: boolean = false;
  public downloadingReport: boolean = false;
  public downloadDisabled: boolean = true;

  public saleReportKey: string = "sale_report_by_inrem";
  public overviewReportKey: string = "auction_overview_report";
  public bidderReportKey: string = "bidders_report";

  public reportTypes: any[] = [
    { name: "Sale Report (by inrem)", value: this.saleReportKey },
    { name: "Auction Overview", value: this.overviewReportKey },
    { name: "Bidders Report", value: this.bidderReportKey }
  ];
  public currentReportType: string = "";
  public canGenerateReport: boolean = true;
  public canDownloadReport: boolean = true;

  constructor(
    private reportService: AuctionReportService,
    private dateService: DateService,
    private alertService: AlertService,
    private appSettingsService: AppSettingsService
  ) { }

  ionViewWillEnter() {
    this.loading = true;

    // sets all value to empty string every time the user revisits page
    this.currentInRemDisplay = "";
    this.totalPropSold = "";
    this.totalPropAdjourned = "";
    this.totalCashDeposits = "";
    this.totalCheckDeposits = "";
    this.totalDeposits = "";
    this.currentReportType = this.saleReportKey;
    this.canGenerateReport = true;
    this.canDownloadReport = true;

    this.appSettingsService.getAppSetting('currentInRem').subscribe((data) => {
      this.currentInRem = data.records[0].value;
      this.selectedInrem = this.currentInRem;
      this.sortInRemList();
    },
      (err) => {
        console.log("Failure in Auction Report Page");
        console.log("Failed to get current InRem: ", err);
        this.alertService.toastError("Failure to get current InRem. Please try again.");
        this.sortInRemList();

        (this.inRemList.length > 0) ? this.selectedInrem = this.inRemList[0].inrem : this.selectedInrem = 1;
        this.loading = false;
      })
  }

  ionViewWillLeave() {
    this.loading = true;
  }

  // Build the body payload for the report based on the selected report type
  private buildReportPayload(downloadReport: boolean = false) {
    var reportPayload = {};
    reportPayload["type"] = this.currentReportType;
    reportPayload["format"] = (downloadReport) ? "csv" : "json";
    reportPayload["data"] = {};

    switch (this.currentReportType) {
      case this.saleReportKey:
        reportPayload["data"]["inrem"] = parseInt(this.selectedInrem);
        break;

      case this.overviewReportKey:
        reportPayload["data"]["inrem"] = parseInt(this.selectedInrem);
        break;

      case this.bidderReportKey:
        reportPayload["data"]["inrem"] = parseInt(this.selectedInrem);
        break;
    }

    return reportPayload;
  }

  // Call the API to generate the JSON version of the report
  public generateReport() {
    this.generatingReport = true;
    this.downloadDisabled = true;
    this.reportParameters = this.buildReportPayload(false);

    this.reportService.getReport(this.reportParameters).subscribe((report) => {
      this.handleReportView(report);

      this.reportGenerated = true;
      this.generatingReport = false;
      this.downloadDisabled = false;
    },
      (err) => {
        console.log("Failure in Auction Report Page");
        console.log("getReport call failed while generating report: ", err);
        this.alertService.toastError("Failure to generate report. Please try again.");
        this.downloadDisabled = false;
        this.generatingReport = false;
      });
  }

  // Populate the report view based on the report type
  private handleReportView(report) {
    switch (this.currentReportType) {
      case this.saleReportKey:
        this.currentInRemDisplay = this.selectedInrem;
        this.totalPropSold = report.report_data.properties_sold || 0;
        this.totalPropAdjourned = report.report_data.properties_adjourned || 0;
        this.totalCashDeposits = report.report_data.total_cash || 0;
        this.totalCheckDeposits = report.report_data.total_check || 0;
        this.totalDeposits = report.report_data.total_deposits || 0;
        break;
    }
  }

  // Call the API to generate the base64 CSV version of the report
  public downloadReport() {
    this.downloadingReport = true;
    this.reportParameters = this.buildReportPayload(true);

    this.reportService.getReport(this.reportParameters).subscribe((report) => {
      this.handleReportDownload(report);
      this.downloadingReport = false;
    },
      (err) => {
        console.log("Failure in Auction Report Page");
        console.log("getReport call failed while downloading report", err);
        this.alertService.toastError("Failure to download report. Please try again.");
        this.downloadingReport = false;
      });
  }

  // Trigger the download of a CSV file from the incoming report base64 data
  private handleReportDownload(report) {
    saveAs(b64toBlob(report.report_data), this.currentReportType + "_" + this.dateService.getTodaysDate() + ".csv");
  }

  public changeInRem(event) {
    this.selectedInrem = event.target.value;
    this.totalPropSold = '';
    this.totalPropAdjourned = '';
    this.totalCashDeposits = '';
    this.totalCheckDeposits = '';
    this.totalDeposits = '';
    this.currentInRemDisplay = '';

    // Handle specific cases where changing inrem should change things based on report type
    switch (this.currentReportType) {
      case this.saleReportKey:
        this.downloadDisabled = true;
        break;
    }
  }

  sortInRemList() {
    this.reportService.getAllInRems().subscribe((data) => {
      this.inRemList = data.records;
      this.inRemList = this.inRemList.sort((a, b) => {
        if (a.inrem > b.inrem) {
          return -1;
        }
        else if (a.inrem < b.inrem) {
          return 1;
        }
        else {
          return 0;
        }
      });

      this.loading = false;
    },
      (err) => {
        console.log("Failure in Auction Report Page");
        console.log("Failed to get all InRems: ", err);
        this.alertService.toastError("Failure to get all InRems. Please try again.");
        this.loading = false;
      });
  }

  public changeReportType(event) {
    this.currentReportType = event.target.value;

    switch (this.currentReportType) {
      case this.saleReportKey:
        this.canGenerateReport = true;
        this.canDownloadReport = true;
        this.downloadDisabled = true;
        this.selectedInrem = this.currentInRem;
        break;

      case this.overviewReportKey:
        this.canGenerateReport = false;
        this.canDownloadReport = true;
        this.downloadDisabled = false;
        this.selectedInrem = this.currentInRem;
        break;

      case this.bidderReportKey:
        this.canGenerateReport = false;
        this.canDownloadReport = true;
        this.downloadDisabled = false;
        this.selectedInrem = this.currentInRem;
        break;
    }
  }
}

const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}