export class Purchaser {
  first_name: string;
  last_name: string;
  name_on_deed: string;
  address_1: string;
  address_2: string;
  city: string;
  state: string;
  zipcode: string;
  phone: string;
  email: string;
  property_auction_id: number = null;
  create_user: string;

  constructor() { }
}