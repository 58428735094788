import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from "@ionic/angular";

@Component({
  selector: 'eca-payment',
  templateUrl: './record-payment.component.html',
  styleUrls: ['./record-payment.component.scss'],
})
export class RecordPaymentComponent implements OnInit {

  sbl: number;
  cash: number;
  check: number;
  public processing: boolean = false;

  constructor(
    private modalController: ModalController,
    public params: NavParams
  ) {
    this.sbl = params.get('sbl') || 0;
    this.cash = params.get('cash') || 0;
    this.check = params.get('check') || 0;
  }

  ngOnInit() {}

  public cancel() {
    this.modalController.dismiss();
  }

  public confirm() {
    this.processing = true;
    setTimeout(() => { this.modalController.dismiss(true); this.processing = false; }, 1000);
  }
}
