import { Injectable } from '@angular/core';

import { ETGAuthService, CognitoService } from "ng-etg-base";
import { User } from "@models/user.model";

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(
        private etgAuth: ETGAuthService,
        private cognitoService: CognitoService
    ) {
        this.etgAuth.setAuthSubject(this.cognitoService);
    }

    /*
    ========================================================================================
        Login Functions
    ========================================================================================
    */
    public login(username: string, password: string): Promise<any> {
        return this.etgAuth.authenticate(username, password);
    }

    public logout() {
        return this.etgAuth.logout();
    }

    /*
    ========================================================================================
        Registration Functions
    ========================================================================================
    */
    public register(email: string, username: string, password: string, attributes: any): Promise<any> {
        return this.etgAuth.register(email, username, password, attributes);
    }

    /*
    ========================================================================================
        User/Auth Functions
    ========================================================================================
    */
    public getCurrentUser(): Promise<any> {
        return this.etgAuth.getCurrentUser();
    }

    public getAccessToken(): Promise<any> {
        return this.etgAuth.getAccessToken();
    }

    public isAuthenticated(): Promise<boolean> {
        return this.etgAuth.isAuthenticated()
    }
}