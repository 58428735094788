export class Bidder {
    id: number;
    bidder_number: number;
    auction_id: number;
    first_name: string;
    last_name: string;
    name_on_deed: string;
    email: string;
    address_1: string;
    address_2: string;
    city: string;
    state: string;
    zipcode: string;
    phone: string;
    notes: string;
    active: boolean
    date_created: string;
    date_updated: string;
    create_user: string;
    update_user: string;
 
    constructor(obj?) {
        if (obj) {
            this.id = obj.id;
            this.bidder_number = Number(obj.bidder_number);
            this.auction_id = Number(obj.auction_id);
            this.first_name = obj.first_name;
            this.last_name = obj.last_name;
            this.name_on_deed = obj.name_on_deed;
            this.email = obj.email;
            this.address_1 = obj.address_1;
            this.address_2 = obj.address_2;
            this.city = obj.city;
            this.state = obj.state;
            this.zipcode = obj.zipcode;
            this.phone = obj.phone;
            this.notes = obj.notes;
            this.active = obj.active;
            this.date_created = obj.date_created;
            this.date_updated = obj.date_updated;
            this.create_user = obj.create_user;
            this.update_user = obj.update_user;
        }
    }
}