import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@guards/auth.guard';

import { PropertySearchPage } from '@pages/property-search/property-search.page';
import { BidderRegistrationPage } from './_pages/bidder-registration/bidder-registration.page';
import { PropertyDetailsPage } from "@pages/property-details/property-details.page";
import { AuctionReportsPage } from '@pages/auction-reports/auction-reports.page';
import { LoginPage } from '@pages/login/login.page';
import { LiveAuctionPage } from './_pages/live-auction/live-auction.page';

const routes: Routes = [
  // Public Routes
  { path: '', redirectTo: 'properties', pathMatch: "full" },
  { path: 'login', loadChildren: '@pages/login/login.module#LoginPageModule' },

  // Guarded Routes
  { path: 'properties', component: PropertySearchPage, canActivate: [AuthGuard] },
  { path: 'auction-reports', component: AuctionReportsPage, canActivate: [AuthGuard] },
  { path: 'live-auction', component: LiveAuctionPage, canActivate: [AuthGuard] },
  { path: 'live-auction/results', component: LiveAuctionPage, canActivate: [AuthGuard] },
  { path: 'property-details/:id', component: PropertyDetailsPage, canActivate: [AuthGuard] },
  { path: 'bidder-registration', component: BidderRegistrationPage, canActivate: [AuthGuard] },
  
  // Default Redirect
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
