import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Bidder } from '@models/bidder.model';

@Injectable({
  providedIn: 'root'
})
export class BidderService {

  constructor(private httpClient: HttpClient) { }


  public registerBidder(bidder: Bidder): Observable<any> {
    return this.httpClient
      .post(`${environment.apiUrl}/bidder`, bidder)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  /*
    Retrieve bidder information.
    Method: GET

    @param bidNum: the bidder number of the bidder we want to retrieve 
    @returns: string
  */
  public getBidderByBidderNumber(bidNum, auctionId): Observable<any> {
    return this.httpClient
      .get(`${environment.apiUrl}/bidder/${bidNum}?auctionId=${auctionId}`)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
}
