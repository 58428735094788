import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PageRefService {

  private page:number;

  constructor() {}

  public getPage(): any {
    // if (localStorage.getItem("page") === null) {
    //   return this.page;
    // }
    // else {
    //   console.log("Parse int: " + localStorage.getItem("page"));
    //   return parseInt(localStorage.getItem("page"));
    // }
    return localStorage.getItem("page");
  }

  public setPage(newPage) {
    this.page = newPage;
    localStorage.setItem("page", newPage);
  }
}